import './Navigation.css';
import { Link} from "react-scroll";

export default function Navigation() {
  return <>
    <header>
      <div className="container nav--container">
        <div className="nav--logo">
          <img src="./assets/ThiboDeReu_logo.svg" alt="logo" />
        </div>

        <nav className='nav--nav'>
          <ul>
            <li><Link to="home" smooth={true} duration={450} offset={-100}>home</Link></li>
            <li><Link to="work" smooth={true} duration={450}>work</Link></li>
            <li><Link to="about" smooth={true} duration={450}>about me</Link></li>
            <li><Link to="contact" smooth={true} duration={450}>contact</Link></li>            
          </ul>
        </nav>
      </div>
    </header>
  </>
}
import About from "../components/About";
import Contact from "../components/Contact";
import Ctablock from "../components/Ctablock";
import Footer from "../components/Footer";
import Landing from "../components/Landing";
import Navigation from "../components/Navigation";
import Work from "../components/Work";

export default function Index() {
  return <>
    <Navigation/>
    <Landing/>
    <Work/>
    <Ctablock/>
    <About/>
    <Contact/>
    <Footer/>
  </>
}
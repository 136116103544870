import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function About() {
  return (
    <section id="about">
      <div className="container h2center">
        <h2 className="headline-2">about me</h2>  
      </div>
      <div className="container--about">
        <img className="content-banner" src="./assets/thibodereu.JPG" alt="profile" />
        
        <div className="about--content">
          <p className="content-title">Hi!</p>
          <p className='content-info paragraph'>
            My name is Thibo De Reu, I'm 19 years old and I study 
            Applied Informatics at HoGent.
            <br/><br/>
            Since a few years I love designing and creating websites.
            Most of the websites I made were only for school projects, 
            until a friend and I had the idea to share our passion
            for web design with others. We raised <strong>TUURBODESIGNS</strong>.
            A company where we design websites for local businesses.
            <br /><br/>
            Take a look at my CV <a href="./assets/CV_ThiboDeReu.pdf" download="CV_ThiboDeReu">here</a>.
          </p>
          <div className="skills">
            <ul>
              <li>Web Design UI</li>
              <li>HTML &amp; CSS</li>
              <li>Javascript</li>
              <li>React</li>
            </ul>
            <div className="plusicon">
              <FontAwesomeIcon icon={solid('plus')} className="icon" color='#FEFEFE'/>
            </div>
            <ul>
              <li>Extravert</li>
              <li>Teamwork</li>
              <li>Leadership</li>
              <li>Driven</li>
            </ul>
          </div>
        </div>

        <div className="circles">
          <img src="./assets/circles.svg" alt="backgroundplaceholder" />
        </div>

      </div>
    </section>
  )
}
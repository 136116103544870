import './Landing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from "react-scroll";

export default function Landing() {
  return ( 
  <div id="home">
    <div className="container home--container">
      <div className="home--content">
        <h3 className='headline-3'>Thibo De Reu - Webdesigner</h3>
        <h1 className='headline-1'>The best design is the 
          <br/>
          simplest one that works
        </h1>
        <div className="ctas">
          <Link className="cta primary" to="work" smooth={true} duration={450}>My Work</Link> 
          <Link className="cta" to="contact" smooth={true} duration={450}>Get In Touch</Link>  
        </div>
      </div>      
      <div className="socials">        
        <a href='mailto:thibo@thibodereu.be'><FontAwesomeIcon icon={solid('envelope')} className="icon" color='#396C75'/></a>
        <a href='https://www.instagram.com/thibo_de_reu/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('instagram')} className="icon" color='#396C75'/></a>
        <a href='https://www.linkedin.com/in/thibo-de-reu-27b335206/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('linkedin-in')} className="icon" color='#396C75'/></a>
      </div>
    </div>
  </div>
  )
}
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from './pages/NotFound.jsx';
import Index from './pages/Index.jsx'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}/>         
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import './Ctablock.css';
import { Link } from "react-scroll";

export default function Ctablock() {
  return (
    <div className="block">
      <div className="container--ctablock">
        <div className="ctatitle">
          <p>Do you like my style?</p> 
          <p>Feel free to contact me.</p> 
        </div>
        <Link className="cta" to="contact" smooth={true} duration={450}>Contact Me</Link>
      </div>  
    </div>
  )
}
import './Work.css';

export default function Work() {
  return ( 
    <section id='work'>
        <div className="container h2center">
          <h2 className="headline-2 section-title">work</h2>  
        </div> 
        <div className='work--card-container'>          
          <div className="work--card c1">
            <div className="card-content">
              <div className="head">
                <p className="name">Podcast Website</p>
                <p className="type">Multiple-page design</p>
              </div>
              <div className="body">
              <p>
                I've created this website for a school project. You can search for podcasts, listen to them and even add them to your personal playlist.
              </p>    
              </div>
              <div className="foot">
                <div className="lang">
                  <p>HTML</p>  
                  <p>SCSS</p>  
                  <p>REACT</p>  
                </div> 
                <div className="cta">
                <a className="arrow" href="https://searchpodcast-bd78c.web.app/" target="_blank" rel="noreferrer noopener">view website</a>  
                </div> 
              </div>
            </div>
            <div className="card-image podcast">              
            </div>
          </div>

          <div className="work--card right c2">
            <div className="card-content">
              <div className="head">
                <p className="name">TUURBODESIGNS</p>
                <p className="type">Multiple-page design</p>
              </div>
              <div className="body">
              <p>
                I've made this website together with Arthur De Letter for our personal business. We've created TUURBODESIGNS
                to make websites for small local businessess.
              </p>    
              </div>
              <div className="foot">
                <div className="lang">
                  <p>HTML</p>  
                  <p>CSS</p>  
                  <p>REACT</p>  
                </div> 
                <div className="cta">
                <a className="arrow" href="https://www.tuurbodesigns.be" rel="noreferrer noopener">view website</a> 
                </div> 
              </div>
            </div>
            <div className="card-image tuurbo">              
            </div>
          </div>

          <div className="work--card c3">
            <div className="card-content">
              <div className="head">
                <p className="name">V-LAN</p>
                <p className="type">One-page design</p>
              </div>
              <div className="body">
              <p>
              This website was for an event that classmates and I hosted at school. We created this website so that classmates could register for our event and also find information about it.</p>    
              </div>
              <div className="foot">
                <div className="lang">
                  <p>HTML</p>  
                  <p>CSS</p>  
                  <p>JAVASCRIPT</p>  
                  <p>PHP</p>  
                </div> 
                <div className="cta">
                  <p>website is offline</p>  
                </div> 
              </div>
            </div>
            <div className="card-image vlan">              
            </div>
          </div>

          <div className="circles1">
            <img src="./assets/circles.svg" alt="backgroundplaceholder" />
          </div>
          <div className="circles2">
            <img src="./assets/circles.svg" alt="backgroundplaceholder" />
          </div>


        </div>
    </section>
  )
} 
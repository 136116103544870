import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Footer() {
  return (
    <footer>
      <div className="container container--footer">
        <p>&copy; Thibo De Reu - {new Date().getFullYear()}</p>
        <div className="socials">        
        <a href='mailto:thibo@thibodereu.be'><FontAwesomeIcon icon={solid('envelope')} className="icon" color='#396C75'/></a>
        <a href='https://www.instagram.com/thibo_de_reu/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('instagram')} className="icon" color='#396C75'/></a>
        <a href='https://www.linkedin.com/in/thibo-de-reu-27b335206/' target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={brands('linkedin-in')} className="icon" color='#396C75'/></a>
      </div>
      </div>
    </footer>
  )
}
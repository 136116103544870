import './Contact.css';
import { useForm } from "react-hook-form";
import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax



export default function Contact() {
  mapboxgl.accessToken = 'pk.eyJ1IjoidGhpYm9kZXJldSIsImEiOiJjbDBpMm5hbTUwMDB3M2ptc2RqZHlwZ3JuIn0.jYyRmvhchaLaVqGrSbzdlg'
  
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(3.6625375);
  const [lat, setLat] = useState(50.9314079 );
  const [zoom, setZoom] = useState(12);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [lng, lat],
    zoom: zoom
    });
    });

  const { register, formState: { errors }, handleSubmit } = useForm();
  const form = useRef();
  const recaptchaRef = useRef(null);

  const sendEmail = async(e) => {
    //e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    console.log(e);
    const params = {
      mail: e.mail, 
      naam: e.naam, 
      bericht: e.bericht,
      'g-recaptcha-response': token,
    };
    
    emailjs.send('service_zymonb6', 'template_u34ld6j', params, 'user_lgUsRcXJc4Z4zaY0fnqWC')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
    });
  };

  return (
    <section id="contact">
      <div className="container h2center">
        <h2 className="headline-2">contact</h2>  
      </div>
      <div className="container--contact">
      
        <form ref={form} onSubmit={handleSubmit(sendEmail)} className="form">
          <div className="flex-vertical">
            <div className='flex-error'>
              <label className="label" htmlFor="naam">Your Name<sup>*</sup></label>
              {errors.naam?.type === 'required' && <p className="error">Name is required</p>}
            </div>
            <input className='input' id="naam" autoComplete='off' {...register("naam", { required: true })} />
            
          </div>
          
          <div className="flex-vertical">
            <div className='flex-error'>
              <label className="label" htmlFor="subject">Subject<sup>*</sup></label>
              {errors.subject?.type === 'required' && <p className="error">Subject is required</p>}
            </div>
            <input type="text" className='input' id="subject" {...register("subject", { required: true })} />
            </div>

          <div className="flex-vertical">
            <div className='flex-error'>
              <label className="label" htmlFor="bericht">Message<sup>*</sup></label>
              {errors.bericht?.type === 'required' && <p className="error">Message is required</p>}
            </div>
            <textarea className='input' id="bericht" {...register("bericht", {required: true})} />
            </div>
          
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LciWZgeAAAAAAOjU9ZjaoV4RMDEmoi6AcAhKyxQ"
          />            

          <div className='mailbtn'>
            <input className="btn btn-primary" type="submit" value={"Send"}/>
          </div>
        </form>

        <div className="circles">
          <img src="./assets/circles.svg" alt="backgroundplaceholder" />
        </div>

        <div className="map">
          <div className="contact-card">
            <p className="contact-title">Contact Details</p>
            <div>
              <p className="contact-header">Address</p>
              <div>
                <p className="contact-body">Vossestraat 15</p>
                <p className="contact-body">9890 Gavere</p>
              </div>
            </div>
            <div>
              <p className="contact-header">Email</p>
              <div>
                <a href='mailto:thibo@thibodereu.be' className="contact-body">thibo@thibodereu.be</a>
              </div>
            </div>
            <div>
              <p className="contact-header">Phone</p>
              <div>
                <a href='callto:+32471811155' className="contact-body">+32 471 81 11 55</a>
              </div>
            </div>
          </div>

          <div ref={mapContainer} className="map-container"/>
        </div>
        
      </div>     
    </section>
  )
}